import { defineStore } from 'pinia'
import { loginOutApi } from '@/api/login'
import router from '@/router'
import { getMenuApi } from '@/api/common'
import { findNodesWithCondition } from '@/utils/util'
import { cloneDeep } from 'lodash-es'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      userInfo: {} as any,
      remoteMenu: {} as any,
      remotePage: [] as any[],
      remoteBtn: [] as any[]
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'store_user',
        storage: localStorage
      }
    ]
  },
  actions: {
    async getMenu() {
      const menuRes = await getMenuApi()
      if (menuRes.code == 0) {
        this.remotePage = findNodesWithCondition(
          cloneDeep(menuRes.data),
          (node) => node.type == 1
        ).map((item: any) => item.uri)
        this.remoteBtn = findNodesWithCondition(
          cloneDeep(menuRes.data),
          (node) => node.type == 2 && node.isButton == 0
        ).map((item: any) => item.permission)
      }
    },
    async logOut() {
      try {
        await loginOutApi({
          seq: this.userInfo.seq
        })
      } finally {
        this.clearUserInfo()
        router.replace('/login')
      }
    },
    clearUserInfo() {
      this.userInfo = {}
    },
    setUserInfo(payload: any) {
      this.userInfo = payload
    }
  }
})
