// @ts-nocheck
import * as CryptoJS from 'crypto-js'
import { ElMessageBox } from 'element-plus'
import { checkVersion } from 'version-rocket'
import { v4 as uuidv4 } from 'uuid'
import { useI18n } from '@/hooks/web/useI18n'
import axios from 'axios'
import { useUserStore } from '@/store/modules/user'

export const hasPermission = (value: string) => {
  const user = useUserStore().userInfo
  if (user?.tenantId === 1) return true
  if (value && value == 'show') return true
  return useUserStore().remoteBtn.some((rule) => {
    const ruleRegex = new RegExp(rule.replace(/\*/g, '.*'))
    return ruleRegex.test(value)
  })
}

// 查找数组里对象中的某个字段值是否有重复
export const hasDuplicateFieldValue = (arr: any[], fieldName: string): boolean => {
  const count = {}
  for (let i = 0; i < arr.length; i += 1) {
    const obj = arr[i]
    const value = obj[fieldName]
    if (count[value]) {
      return false
    }
    count[value] = 1
  }
  return true
}

export const formatNameAndPhone = (payload) => {
  if (payload.length !== 0) {
    return payload.map((item) => {
      const result = []
      if (item.name) result.push(item.name)
      if (item.phone) result.push(item.phone)
      if (item.email) result.push(item.email)
      return {
        uid: item.uid,
        name: result.join('/')
      }
    })
  }
  return []
}

export const getRandomStr = () => {
  return new Date().getTime() + Math.random().toString(36).slice(-6)
}

export const getUserConnection = (row: any) => {
  if (row.phone && row.email) {
    return `${row.phone} , ${row.email}`
  } else if (row.phone) {
    return row.phone
  } else if (row.email) {
    return row.email
  } else {
    return ''
  }
}

export const getUploadFileName = (
  basePath: 'img/' | 'img_store/' | 'img_franchisee/',
  fileType: string
) => {
  return basePath + getRandomStr() + '.' + fileType
}

export const getAllNodes = (node: any[] = [], arr = []): any[] => {
  for (const item of node) {
    arr.push(item.uri)
    const parentArr = []
    if (item.children) {
      parentArr.push(...item.children)
    }
    if (parentArr && parentArr.length) getAllNodes(parentArr, arr)
  }
  return arr
}

/**
 * 根据过滤条件返回新的树
 * @param {Array} nodes 树
 * @param {Function} predicate 过滤条件
 */
export const filterNode = (nodes, predicate) => {
  if (!(nodes && nodes.length)) {
    return []
  }
  const newChildren: any[] = []
  for (const node of nodes) {
    if (predicate(node)) {
      newChildren.push(node)
      node.children = filterNode(node.children, predicate)
    } else {
      newChildren.push(...filterNode(node.children, predicate))
    }
  }
  return newChildren
}
export const findNodesWithCondition = (trees, condition) => {
  const result = []

  // 递归函数
  function traverse(node) {
    if (condition(node)) {
      result.push(node)
    }

    if (node.children) {
      for (const child of node.children) {
        traverse(child)
      }
    }
  }

  for (const tree of trees) {
    traverse(tree)
  }

  return result
}

export const filterTreeList = (treeList, filterCondition) => {
  return treeList.reduce((filtered, node) => {
    if (filterCondition(node)) {
      const filteredNode = JSON.parse(JSON.stringify(node))
      if (filteredNode.children) {
        filteredNode.children = filterTreeList(filteredNode.children, filterCondition)
      }
      filtered.push(filteredNode)
    }
    return filtered
  }, [])
}

export const checkUpdate = () => {
  let latest = ''
  let interval = null
  interval = setInterval(async () => {
    const res = await axios.get(`${location.origin}/version.json`)
    if (res.data) {
      const current = JSON.stringify(res.data)
      if (latest && latest !== current) {
        clearInterval(interval)
        const { t } = useI18n()
        ElMessageBox.confirm(t('common.contentTheCurrentUpdated'), t('common.prompt'), {
          confirmButtonText: t('common.refresh'),
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          closeOnHashChange: false,
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
          location.reload()
        })
      } else {
        latest = current
      }
    }
  }, 10 * 1000)
}

/**
 *加密处理
 */
export const encryption = (password: string) => {
  const params = {
    data: {
      password: password
    },
    key: 'xiliu&lo@u%12345',
    param: ['password']
  }
  const { data, param } = params
  let key = params.key
  const result = JSON.parse(JSON.stringify(data))
  param.forEach((ele) => {
    const data = result[ele]
    key = CryptoJS.enc.Utf8.parse(key)
    const iv = key
    // 加密
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
    })
    result[ele] = encrypted.toString()
  })
  return result.password
}

export const getDeviceId = () => {
  let cacheDeviceId = localStorage.getItem('deviceId')
  if (!cacheDeviceId) {
    cacheDeviceId = uuidv4()
    localStorage.setItem('deviceId', cacheDeviceId)
  }
  return cacheDeviceId
}

export const isMobile = () => {
  return window.innerWidth <= 600
}

export const sleep = (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('')
    }, time)
  })
}

/**
 * 输入一个树，返回所有节点的id
 */
export const getAllNodeIds = (trees) => {
  const ids: any[] = []
  function traverse(node) {
    ids.push(node.id)
    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        traverse(child)
      }
    }
  }
  for (const tree of trees) {
    traverse(tree)
  }
  return ids
}
