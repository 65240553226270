<script setup lang="ts">
import { computed } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { ConfigGlobal } from '@/components/ConfigGlobal'
import { useDesign } from '@/hooks/web/useDesign'
import { useStorage } from '@/hooks/web/useStorage'
import { onMounted } from 'vue'
import { checkUpdate } from './utils/util'
import { useUserStore } from './store/modules/user'
import { ElMessageBox } from 'element-plus'
import { useI18n } from '@/hooks/web/useI18n'
import { cloneDeep } from 'lodash-es'
const { t } = useI18n()

const { getPrefixCls } = useDesign()

const prefixCls = getPrefixCls('app')

const appStore = useAppStore()

const currentSize = computed(() => appStore.getCurrentSize)

const greyMode = computed(() => appStore.getGreyMode)

const { getStorage } = useStorage()

import { usePermissionStore } from '@/store/modules/permission'
import { RouteRecordRaw, useRouter } from 'vue-router'

const permissionStore = usePermissionStore()

const { addRoute } = useRouter()

onMounted(async () => {
  checkUpdate()
  refreshRouter()
  setInterval(async () => {
    refreshMenu()
  }, 1000 * 60)
})

const refreshRouter = async () => {
  await useUserStore().getMenu()
  await permissionStore.generateRoutes('none').catch(() => {})
  permissionStore.getAddRouters.forEach((route) => {
    addRoute(route as RouteRecordRaw)
  })
  permissionStore.setIsAddRouters(true)
}

const refreshMenu = async () => {
  if (useUserStore().userInfo?.token) {
    const oldPages = cloneDeep(useUserStore().remotePage)
    const oldBtns = cloneDeep(useUserStore().remoteBtn)
    await useUserStore().getMenu()
    const newPages = cloneDeep(useUserStore().remotePage)
    const newBtns = cloneDeep(useUserStore().remoteBtn)

    if (
      JSON.stringify(oldPages) !== JSON.stringify(newPages) ||
      JSON.stringify(oldBtns) !== JSON.stringify(newBtns)
    ) {
      await permissionStore.generateRoutes('none').catch(() => {})
      permissionStore.getAddRouters.forEach((route) => {
        addRoute(route as RouteRecordRaw)
      })
      ElMessageBox.confirm(t('common.contentTheCurrentUpdated'), t('common.prompt'), {
        confirmButtonText: t('common.refresh'),
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
        location.reload()
      })
    }
  }
}

// 根据浏览器当前主题设置系统主题色
const setDefaultTheme = () => {
  if (getStorage('isDark') !== null) {
    appStore.setIsDark(getStorage('isDark'))
    return
  }
  appStore.setIsDark(false)
}

setDefaultTheme()
</script>

<template>
  <ConfigGlobal :size="currentSize">
    <RouterView :class="greyMode ? `${prefixCls}-grey-mode` : ''" />
  </ConfigGlobal>
</template>

<style lang="less">
@prefix-cls: ~'@{namespace}-app';

.size {
  width: 100%;
  height: 100%;
}

html,
body {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
  .size;

  #app {
    .size;
  }
}

.@{prefix-cls}-grey-mode {
  filter: grayscale(100%);
}

.el-message {
  top: 32px !important;
}

.el-card {
  border: none;
  box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.1);
}

.el-form-item__label {
  pointer-events: none;
  line-height: normal !important;
  height: auto !important;
  display: flex;
  align-items: center !important;
}

@import '@/styles/button.less';

.el-button--primary.is-link.is-disabled {
  color: rgb(185, 181, 181);
}
.el-button--primary.is-link.is-disabled:hover {
  color: rgb(185, 181, 181);
}

@media screen and (max-width: 600px) {
  .el-dialog {
    width: 95vw !important;
    box-sizing: border-box !important;
  }
  .el-dialog__body {
    width: 100% !important;
    padding: 10px !important;
  }
}
</style>
