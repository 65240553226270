import request from '@/config/axios'

export const getUidApi = (params: any) => {
  return request.get({ url: '/upms-web/admin/user/search', params })
}

export const getEmailApi = (params: any) => {
  return request.get({ url: '/upms-web/admin/user/email/search', params })
}

export const getMenuApi = () => {
  return request.get({ url: '/upms-web/admin/user/menu' })
}

export const getFileUploadSignApi = (key: string) => {
  return request.get({ url: `/device-admin/admin/cabinet/file/upload/getSign?key=${key}` })
}

// 发送指令
export const cabinetDeviceCommandApi = (data: any) => {
  return request.post({ url: `/device-admin/admin/cabinet/device/command`, data })
}

// 获取结果
export const cabinetDeviceCommandResultApi = (uuid) => {
  return request.get({
    url: `/device-admin/admin/cabinet/device/command/result`,
    params: { texNo: uuid }
  })
}
