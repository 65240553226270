function setRemUnit() {
  let width = window.innerWidth
  if (width >= 1400) {
    width = 1400
  }
  var rem = width / 100
  document.documentElement.style.fontSize = rem + 'px'
  if (width <= 600) {
    document.documentElement.style.fontSize = 16 + 'px'
  }
}

window.addEventListener('resize', setRemUnit)
window.addEventListener('pageshow', () => {
  setRemUnit()
})
