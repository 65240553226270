import type { App } from 'vue'
import { Icon } from './Icon'
import { ContentWrap } from './ContentWrap'
import ACard from '@/components/ACard'
import ABack from '@/components/ABack'

export const setupGlobCom = (app: App<Element>): void => {
  app.component('Icon', Icon)
  app.component('ContentWrap', ContentWrap)
  app.component('ACard', ACard)
  app.component('ABack', ABack)
}
