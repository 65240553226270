<template>
  <div class="color-[#333333] text-[14px] flex items-center mb-10px cursor-pointer" @click="goback">
    <span>&lt;</span>
    <span class="ml-5px">{{ t('common.backPrev') }}</span>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
const { back } = useRouter()
import { useI18n } from '@/hooks/web/useI18n'

const { t } = useI18n()
const goback = () => {
  back()
}
</script>

<style lang="less" scoped></style>
