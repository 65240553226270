<script setup lang="ts">
import { ElCard, ElTooltip } from 'element-plus'
import { propTypes } from '@/utils/propTypes'
import { useDesign } from '@/hooks/web/useDesign'

const { getPrefixCls } = useDesign()

const prefixCls = getPrefixCls('content-wrap')

defineProps({
  title: propTypes.string.def(''),
  message: propTypes.string.def('')
})
</script>

<template>
  <ElCard :class="[prefixCls]" shadow="never">
    <template v-if="title" #header>
      <div class="flex items-center justify-between">
        <div>
          <span class="title text-16px font-700">{{ title }}</span>
          <ElTooltip v-if="message" effect="dark" placement="right">
            <template #content>
              <div class="max-w-200px">{{ message }}</div>
            </template>
            <Icon class="ml-5px" icon="bi:question-circle-fill" :size="14" />
          </ElTooltip>
        </div>
        <slot name="headerMenu"></slot>
      </div>
    </template>
    <div>
      <slot></slot>
    </div>
  </ElCard>
</template>
