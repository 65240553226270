<template>
  <div class="page">
    <div class="title">
      <span>{{ t('10380') }}</span>
      <span class="info">{{ t('10381') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useI18n } from '@/hooks/web/useI18n'
const { t } = useI18n()
const router = useRouter()
</script>

<style scoped lang="less">
.page {
  width: 100%;
  height: calc(100vh - 100px);
  background: url(../../../assets/imgs/loginBg.png) no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #596ac8;
    letter-spacing: 5px;
    margin-top: -30px;
    .info {
      margin-top: 40px;
      font-size: 30px;
      font-weight: 400;
      color: rgba(89, 106, 200, 0.5);
      letter-spacing: 4px;
    }
  }
}
</style>
